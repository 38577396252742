<template>
  <div class="portfolio-page-content">
    <Main class="general-background">
      <p style="font-size: 14px; font-weight: 500;">{{ $t("generic.titles.load_portfolio_text") }}</p>
      <a-row :gutter="25" class="responsive-select-btn">
        <a-col :span="24">
          <a-select
            v-model:value="portfolioValue"
            mode="multiple"
            style="width: 100%"
            :placeholder="$t('generic.buttons.select_portfolio')"
            @focus="focus"
            ref="select"
            @change="handleChange"
            allowClear
            :showSearch="false"
            :loading="loading || portfolioStore.loading"
            :max-tag-count="5"
          >
            <a-select-option
              v-for="(v, i) in portfolioStore.listOfPortfolios"
              :value="v.id"
              v-bind:key="i"
              :title="v.portfolio_name"
              @change="handleChange"
            >
              {{ v.portfolio_name }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :span="24">
          <a-row class="mt-5" :gutter="4" style="row-gap: 4px;">
            <a-tooltip :title="$t('generic.buttons.portfolio_actions_tooltips.open')">
              <sdButton
                size="small"
                :type="actionType === 'open' ? 'primary' : 'secondary'"
                @click="handleChangeAction('open')"
                class="action-button"
                :loading="portfolioStore.loadingOpen"
              >
                {{ $t("generic.buttons.portfolio_actions.open") }}
              </sdButton>
            </a-tooltip>
            <a-tooltip :title="$t('generic.buttons.portfolio_actions_tooltips.edit')">
              <sdButton
                size="small"
                :type="actionType === 'edit' ? 'primary' : 'secondary'"
                @click="handleChangeAction('edit')"
                class="action-button"
              >
                {{ $t("generic.buttons.portfolio_actions.edit") }}
              </sdButton>
            </a-tooltip>
            <a-popconfirm
              :title="$t('generic.titles.delete_portfolios')"
              :ok-text="$t('search_funds.select_family_columns.yes')"
              placement="top"
              :cancel-text="$t('search_funds.select_family_columns.no')"
              @confirm="handleChangeAction('remove')"
              v-if="positions.length > 0"
            >
            <a-tooltip :title="$t('generic.buttons.portfolio_actions_tooltips.remove')">
                <sdButton
                  size="small"
                  :type="actionType === 'remove' ? 'primary' : 'secondary'"
                  class="action-button"
                  :loading="portfolioStore.loadingDelete"
                >
                  {{ $t("generic.buttons.portfolio_actions.remove") }}
                </sdButton>
              </a-tooltip>
            </a-popconfirm>
            <a-tooltip :title="$t('generic.buttons.portfolio_actions_tooltips.remove')" v-else>
              <sdButton
                size="small"
                :type="actionType === 'remove' ? 'primary' : 'secondary'"
                class="action-button"
                @click="actionType = 'remove'"
              >
                {{ $t("generic.buttons.portfolio_actions.remove") }}
              </sdButton>
            </a-tooltip>
            <a-tooltip :title="$t('generic.buttons.portfolio_actions_tooltips.rename')">
              <sdButton
                size="small"
                :type="actionType === 'rename' ? 'primary' : 'secondary'"
                @click="handleChangeAction('rename')"
                class="action-button"
              >
                {{ $t("generic.buttons.portfolio_actions.rename") }}
              </sdButton>
            </a-tooltip>
            <a-tooltip :title="$t('generic.buttons.portfolio_actions_tooltips.send_mail')">
              <sdButton
                size="small"
                type="secondary"
                :loading="loadingMail"
                @click="handleSendSummaryMail()"
                class="action-button"
                v-if="!authStore.restrictions.includes(Restrictions['SendPortfolioMail'])"
              >
                {{ $t("generic.buttons.portfolio_actions.send_mail") }}
              </sdButton>
            </a-tooltip>
          </a-row>
        </a-col>
        <a-col :span="24" class="mt-4">
          <template v-if="actionType === 'edit' && positions.length === 1">
            <a-row class="mt-4" gutter="16">
              <a-col :span="24" class="mb-4">
                <p style="border-bottom: 2px solid; padding-bottom: 8px;">{{ $t('generic.titles.edit_portfolio_params') }}</p>
              </a-col>
              <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.risk') }}</label>
                <div>
                  <a-select
                    mode="select"
                    v-model:value="portfolioParams.risk"
                    style="width: 60%; border: 1px solid;"
                    :placeholder="$t('generic.options.select_one')"
                  >
                    <a-select-option
                      v-for="(key, index) in riskLevels"
                      :key="key"
                      :value="index"
                      :title="$t(`generic.risk_options.${key}`, key)"
                    >
                      {{ $t(`generic.risk_options.${key}`, key) }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-col>
              <!-- <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.value_at') }}</label>
                <div>
                  <a-select
                    mode="select"
                    v-model:value="portfolioParams.value_at"
                    style="width: 60%; border: 1px solid;"
                  >
                    <a-select-option
                      :key="index"
                      :value="authStore['config']['value_at_points'][key]"
                      v-for="(key, index) in value_at_points"
                    >{{ $t(`generic.portfolio_params.value_at_options.${key}`, key) }}</a-select-option>
                  </a-select>
                </div>
              </a-col> -->
              <!-- <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.send_mail_every') }}</label>
                <div>
                  <a-select
                    mode="select"
                    v-model:value="portfolioParams.send_mail_every"
                    style="width: 60%; border: 1px solid;"
                    :placeholder="$t('generic.options.select_one')"
                    size="small"
                  >
                    <a-select-option
                      v-for="(key, index) in send_mail_periods"
                      :key="index"
                      :value="key"
                      :title="key"
                    >
                    {{ getOptionLabel(key, index) }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-col> -->
              <!-- <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.depository') }}</label>
                <div>
                  <a-input
                    v-model:value="portfolioParams.depository"
                    style="width: 60%; height: 40px;"
                  ></a-input>
                </div>
               </a-col> -->
              <!-- <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.group') }}</label>
                <div>
                  <a-input
                    v-model:value="portfolioParams.group"
                    style="width: 60%; height: 40px;"
                  ></a-input>
                </div>
              </a-col> -->
              <a-col :span="24" class="mt-4"></a-col>
              <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.include_benchmark') }}</label>
                <div style="width: 100%;">
                  <a-checkbox
                    :checked="portfolioParams.include_benchmark"
                    @change="portfolioParams.include_benchmark = !portfolioParams.include_benchmark"
                  >{{ $t("generic.portfolio_params.true") }}</a-checkbox>
                  <a-checkbox
                    :checked="!portfolioParams.include_benchmark"
                    @change="portfolioParams.include_benchmark = !portfolioParams.include_benchmark"
                  >{{ $t("generic.portfolio_params.false") }}</a-checkbox>
                </div>
              </a-col>
              <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.no_rebalance') }}</label>
                <div style="width: 100%;">
                  <a-checkbox
                    :checked="portfolioParams.no_rebalance"
                    @change="portfolioParams.no_rebalance = !portfolioParams.no_rebalance"
                  >{{ $t("generic.portfolio_params.true") }}</a-checkbox>
                  <a-checkbox
                    :checked="!portfolioParams.no_rebalance"
                    @change="portfolioParams.no_rebalance = !portfolioParams.no_rebalance"
                  >{{ $t("generic.portfolio_params.false") }}</a-checkbox>
                </div>
              </a-col>
              <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.include_compositions') }}</label>
                <div style="width: 100%;">
                  <a-checkbox
                    :checked="portfolioParams.include_compositions"
                    @change="portfolioParams.include_compositions = !portfolioParams.include_compositions"
                  >{{ $t("generic.portfolio_params.true") }}</a-checkbox>
                  <a-checkbox
                    :checked="!portfolioParams.include_compositions"
                    @change="portfolioParams.include_compositions = !portfolioParams.include_compositions"
                  >{{ $t("generic.portfolio_params.false") }}</a-checkbox>
                </div>
              </a-col>
              <a-col :span="24" class="mt-4"></a-col>
              <a-col :xs="24" :md="8" style="display: flex; align-items: center;">
                <label style="font-size: 14px;">{{ $t('generic.portfolio_params.created_at') }}</label>
                <label class="date-label">{{ portfolioParams.created_at }}</label>
              </a-col>
              <a-col :xs="24" :md="8" style="display: flex; align-items: center;">
                <label style="font-size: 14px;">{{ $t('generic.portfolio_params.updated_at') }}</label>
                <label class="date-label">{{ portfolioParams.updated_at }}</label>
              </a-col>
              <a-col :span="24" class="mt-4"></a-col>
              <!-- <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.days_between_reviews') }}</label>
                <div style="width: 100%;">
                  <a-select
                    mode="select"
                    v-model:value="portfolioParams.days_between_reviews"
                    style="width: 60%; border: 1px solid;"
                    :placeholder="$t('generic.options.select_one')"
                  >
                    <a-select-option
                      v-for="(key, index) in between_reviews_periods"
                      :key="index"
                      :value="key"
                      :title="key"
                    >
                      {{ key }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-col>
              </a-col> -->
              <!-- <a-col :xs="24" :md="8">
                <label class="para-label">{{ $t('generic.portfolio_params.delete_positions') }}</label>
                <a-row style="width: 75%;" :justify="'space-between'" :align="'center'">
                  <a-date-picker
                    v-model:value="portfolioParams.delete_positions" 
                    :format="'DD/MM/YYYY'"
                    style="width: 40%;"
                  >
                    <template #suffixIcon>
                    </template>
                  </a-date-picker>
                  <label style="display: flex; align-items: center;">{{ $t('generic.portfolio_params.to') }}</label>
                  <a-date-picker
                    :value="moment()"
                    :format="'DD/MM/YYYY'"
                    style="width: 40%;"
                    :disabled="true"
                  >
                    <template #suffixIcon>
                    </template>
                  </a-date-picker>
                </a-row>
              </a-col> -->
              <a-col class="mt-5">
                <!-- <a-popconfirm
                  :title="$t('generic.portfolio_params.confirm_delete_positions')"
                  ok-text="Yes"
                  placement="top"
                  cancel-text="No"
                  @confirm="editPortfolio()"
                  v-if="portfolioParams.delete_positions"
                >
                  <sdButton
                    type="primary"
                    :loading="portfolioStore.loadingEdit"
                    style="width: 10rem;"
                  >
                    {{ $t('generic.buttons.save') }}
                  </sdButton>
                </a-popconfirm> -->
                <sdButton
                  type="primary"
                  :loading="portfolioStore.loadingEdit"
                  style="width: 10rem;"
                  @click="editPortfolio()"
                >
                  {{ $t('generic.buttons.save') }}
                </sdButton>
              </a-col>
            </a-row>
          </template>
          <template v-if="actionType === 'rename'">
            <a-row>
              <a-input v-model:value="renameValue" style="flex-grow: 1; width: 0;"></a-input>
              <sdButton
                type="primary"
                :disabled="renameDisable"
                @click="renamePortfolio()"
                :loading="portfolioStore.loadingRename"
              >{{ $t('generic.buttons.save') }}</sdButton>
            </a-row>
          </template>
        </a-col>
      </a-row>
      <a-row class="panel-container" v-if="!authStore.restrictions.includes(Restrictions['PortfolioDashboard'])">
        <a-col :span="24">
          <label style="font-weight: bold;">{{ $t('generic.titles.portfolio_control_panel') }}</label>
          <p class="mt-2" style="font-size: 14px;">{{ $t('portfolio_control.description') }}</p>
        </a-col>
        <a-col :span="24" class="mt-4">
          <sdButton
            type="primary"
            @click="openPortfolioControl()"
            :loading="controlStore.loading"
          >{{ $t('generic.buttons.open_portfolio_control') }}</sdButton>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script setup>
import { Main } from "../styled";
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { useAuthStore } from "@/stores/AuthStore";
import { usePortfolioControlStore } from "@/stores/PortfolioControlStore";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
import Restrictions from "@/config/restriction/restrict_functions";

const authStore = useAuthStore();
const portfolioStore = usePortfolioStore();
const controlStore = usePortfolioControlStore();
const router = useRouter();

const { t ,locale } = useI18n();

const loading = ref(false);
const loadingMail = ref(false);
const selected = ref(1);
const actionType = ref('');
const renameValue = ref('');
const portfolioValue = ref(undefined);
const positions = ref([]);
const portfolioParams = ref({
  risk: null,
  depository: '',
  group: '',
  include_benchmark: null,
  no_rebalance: null,
  include_compositions: null,
  created_at: null,
  updated_at: null,
  send_mail_every: null,
  days_between_reviews: null,
  value_at: null,
  delete_positions: null,
});

const ids = computed(() => {
  return positions.value.length > 0 ? positions.value.join(",") : "1";
});

const renameDisable = computed(() => {
  return positions.value.length !== 1 || !renameValue.value;
});

const riskLevels = computed(() => Object.keys(authStore.config["risk_levels"]));

const send_mail_periods = computed(() => [ 'none', ...authStore.config["send_mail_every_periods"] ]);

// const between_reviews_periods = computed(() => authStore.config["between_review_periods"]);

// const value_at_points = computed(() => Object.keys(authStore.config["value_at_points"]));

// const getOptionLabel = (key, index) => {
//   if (index === 0) return t(`generic.portfolio_params.send_mail_options.none`);
//   return `${parseInt(key)} ${t(`generic.portfolio_params.send_mail_options.${key.slice(1)}`)}`;
// };

const handleChangeAction = async (action) => {
  actionType.value = action;
  switch(action) {
    case 'open':
      await openPortfolio();
      break;
    case 'remove':
      await removePortfolio();
      break;
    case 'rename':
      renameValue.value = positions.value.length === 1 ? portfolioStore.listOfPortfolios?.find(e => Object.values(selected.value).includes(e.id))?.portfolio_name : '';
      break;
    default:
      break;     
  }
};

const handleSendSummaryMail = async () => {
  loadingMail.value = true;
  setTimeout(() => {
    loadingMail.value = false;
    message.success(t('generic.buttons.portfolio_actions.will_receive_mail'));
  }, 500);
  await portfolioStore.sendSummaryMail(positions.value,locale);
};

const openPortfolio = async () => {
  if (positions.value && positions.value.length) {
    portfolioStore.portfolio_type = positions.value.length > 1 ? 3 : 2;
    portfolioStore.original_portfolio_name = positions.value.length > 1 ? 'total' : portfolioStore.listOfPortfolios?.find(e => Object.values(selected.value).includes(e.id))?.portfolio_name;
    await portfolioStore.loadPortfolios(positions.value);
    router.push({ name: "portfolio-overview-ids", params: { ids: ids.value } });
  }
};

const removePortfolio = async () => {
  if (positions.value && positions.value.length) {
    await portfolioStore.removePortfolios(positions.value).then(() => {
      portfolioValue.value = undefined;
      selected.value = null;
    });
  }
};

const renamePortfolio = async () => {
  if (positions.value.length === 1 && !!renameValue.value) {
    await portfolioStore.renamePortfolio(positions.value[0], renameValue.value);
  }
};

const openPortfolioControl = async () => {
  await controlStore.getPanelData();
  router.push({ name: 'portfolio-control-panel' });
};

const convertedPeriods = (period) => {
  if (!period || period === 'none') return null;
  if (!isNaN(Number(period))) return period;
  const value = parseInt(period);
  const unit = period.slice(-1);
  let days = 0;

  if (unit === 'd') {
    days = value;
  } else if (unit === 'w') {
    days = value * 7;
  } else if (unit === 'm') {
    days = value * 30;
  } else if (unit === 'y') {
    days = value * 365;
  }

  return days;
};

const editPortfolio = async () => {
  const payload = {};
  payload['risk'] = portfolioParams.value.risk;
  payload['depository'] = portfolioParams.value.depository;
  payload['group'] = portfolioParams.value.group;
  payload['include_benchmark'] = portfolioParams.value.include_benchmark ? 1 : 0;
  payload['include_compositions'] = portfolioParams.value.include_compositions ? 1 : 0;
  payload['no_rebalance'] = portfolioParams.value.no_rebalance ? 1 : 0;
  payload['send_mail_every'] = convertedPeriods(portfolioParams.value.send_mail_every);
  payload['days_between_reviews'] = convertedPeriods(portfolioParams.value.days_between_reviews);
  payload['value_at'] = portfolioParams.value.value_at;
  //payload['delete_positions'] = portfolioParams.value.delete_positions?.format('YYYY-MM-dd');
  payload['delete_positions'] = null;
  await portfolioStore.editPortfolio(selected.value, payload);
};


const handleChange = (value) => {
  selected.value = value;
  positions.value = value;
  if (positions.value.length === 1) {
    const selectedPortfolio = portfolioStore.listOfPortfolios?.find(e => Object.values(selected.value).includes(e.id));
    renameValue.value = selectedPortfolio?.portfolio_name || '';
    portfolioParams.value.risk = selectedPortfolio?.risk || null;
    portfolioParams.value.depository = selectedPortfolio?.depositary || '';
    portfolioParams.value.group = selectedPortfolio?.portfolio_group || '';
    portfolioParams.value.include_benchmark = selectedPortfolio?.include_benchmark === 1;
    portfolioParams.value.include_compositions = selectedPortfolio?.include_compositions === 1;
    portfolioParams.value.no_rebalance = selectedPortfolio?.no_rebalance === 1;
    portfolioParams.value.created_at = !selectedPortfolio?.created_at ? null : moment(selectedPortfolio.created_at).format('DD/MM/YYYY');
    portfolioParams.value.updated_at = !selectedPortfolio?.updated_at ? null : moment(selectedPortfolio.updated_at).format('DD/MM/YYYY');
    portfolioParams.value.send_mail_every = send_mail_periods.value.find(e => convertedPeriods(e) === selectedPortfolio?.send_mail_every) || selectedPortfolio?.send_mail_every;
    portfolioParams.value.days_between_reviews = send_mail_periods.value.find(e => convertedPeriods(e) === selectedPortfolio?.days_between_reviews) || selectedPortfolio?.days_between_reviews;
    portfolioParams.value.value_at = selectedPortfolio?.value_at || null;
    //portfolioParams.value.delete_positions = moment().subtract(1, 'year');
    portfolioParams.value.delete_positions = null;
  }
};

onMounted(async () => {
  loading.value = true;
  await portfolioStore.getUserPortfolios();
  loading.value = false;
});
</script>

<style scoped lang="scss">
.action-button {
  margin-right: 8px;
  width: 8rem;
}
:deep(.ant-select) {
  .ant-select-selector {
    background-color: #F2F2F2;
  }
}

.para-label {
  font-size: 14px;
  margin-bottom: 8px;
}

.date-label {
  margin-left: 12px;
  font-size: 14px;
  padding: 1px 4px;
  background-color: #02b38817;
}

.portfolio-page-content {
  padding: 0 5rem;
}

:deep(.ant-page-header) {
  padding-bottom: 4px;
  padding-left: 0px;
  font-size: 16px;
}

.panel-container {
  margin-top: 2rem;
  padding: 1.5rem 0;
  border-top: 2px solid #02B388;
  border-bottom: 2px solid #02B388;
}

:deep(.ant-select-selection-item) {
  background-color: #EDEDED !important;
}

@media only screen and (max-width: 768px) {
  .portfolio-page-content {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .responsive-select-btn {
    display: block;
    & > * {
      min-width: 100%;
      margin: 20px 0;
    }
  }
}
</style>
